export const services = [
  {
    text: "خدمة تعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن وتوزيعها. يمكننا تلبية احتياجاتك في تعبئة وتغليف منتجاتك بجودة عالية وتوصيلها في الوقت المحدد وتقديم حلاً شاملاً لاحتياجاتك في مجال تغليف المنتجات. نحن متخصصون في تلبية احتياجات قطاع الأغذية واللحوم ونقدم خدمات تعبئة وتغليف متميزة.",
    image: require("../assets/icons/discount.png"),
    title: "خدمة تعبئة وتغليف"
  },
  {
    text: "تقديم عروض جديدة على تعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن. احصل على أفضل الصفقات والتخفيضات عند اختيار خدماتنا لتعبئة وتغليف منتجاتك وضمان أن تكون عبوات منتجاتك جاهزة للسوق. نحن نسعى جاهدين لتقديم خدمات مبتكرة ومخصصة لعملائنا.",
    image: require("../assets/icons/new-offer.png"),
    title: "عروض جديدة"
  },
  {
    text: "خدمة توفير عروض خاصة على تعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن. نحن نقدم أسعارًا مخفضة لزبائننا المميزين ونهتم بتلبية احتياجات تغليف منتجاتك بدقة. نسعى إلى تحقيق أقصى قيمة لعملائنا.",
    image: require("../assets/icons/offer.png"),
    title: "عروض خاصة"
  },
  {
    text: "توفير تسعيرة مناسبة لتعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن. نحن نهتم بميزانيتك ونقدم أسعارًا تنافسية وجودة عالية في تعبئة منتجاتك. نحن نفهم أهمية الاقتصاد والكفاءة في عمليات تعبئة وتغليف المنتجات.",
    image: require("../assets/icons/price-tag.png"),
    title: "تسعير مناسب"
  },
  {
    text: "تسهيل وقت التوصيل لتعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن. نحن نلتزم بتقديم خدمة توصيل سريعة وفعالة لتصل منتجاتك إلى العملاء في الوقت المناسب. نحن نفهم أهمية توقيت التسليم في عمليات توزيع المنتجات.",
    image: require("../assets/icons/time.png"),
    title: "وقت التوصيل"
  },
  {
    text: "خدمة تخزين منتجاتك بأمان وفعالية. نحن نوفر مساحات تخزين مجهزة بأحدث التقنيات للحفاظ على جودة منتجاتك وضمان توزيعها في أفضل حالة.",
    image: "https://www.pngall.com/wp-content/uploads/5/Storage-PNG-Picture.png",
    title: "خدمة تخزين"
  },
  {
    text: "تقديم خدمة استشارية في مجال تعبئة وتغليف المنتجات. نحن نقدم خبرتنا في تطوير استراتيجيات التغليف وتحسين عمليات التعبئة لزيادة كفاءة منتجاتك.",
    image: "https://alejadh.com/wp-content/uploads/2018/12/business-consulting-png.png",
    title: "خدمة استشارية"
  },
  {
    text: "خدمة توزيع سلسة للمنتجات إلى النقاط البيع والعملاء. نحن نمتلك شبكة توزيع موثوقة وفريق عمل محترف لضمان وصول منتجاتك بسرعة وفعالية.",
    image: "https://cdn-icons-png.flaticon.com/512/3505/3505356.png",
    title: "خدمة توزيع"
  },
  {
    text: "خدمة التعبئة الفريدة والمخصصة. نحن نقدم تصميم وتصنيع عبوات مخصصة تناسب منتجاتك بدقة وتساعد في تسويقها بشكل فعال.",
    image: "https://custompackagingpro.com/media/1669407125KraftBoxes.png",
    title: "تعبئة مخصصة"
  },
  {
    text: "خدمة فحص وجودة منتجاتك قبل التغليف. نحن نضمن جودة منتجاتك من خلال إجراء فحص دقيق وفحص جودة قبل تعبئتها وتغليفها.",
    image: "https://cdn-icons-png.flaticon.com/512/4862/4862471.png",
    title: "فحص الجودة"
  },
  {
    text: "خدمة توزيع منتجاتك عبر قنوات التجزئة الرئيسية. نحن نساعدك في الوصول إلى أكبر عدد ممكن من الزبائن من خلال توزيع منتجاتك في سلاسة إلى متاجر البيع بالتجزئة.",
    image: "https://cdn-icons-png.flaticon.com/512/5457/5457133.png",
    title: "توزيع التجزئة"
  },
  {
    text: "خدمة تصميم عبوات منتجاتك بشكل إبداعي ومبتكر. نحن نساعدك في جعل منتجاتك تبرز في السوق من خلال تصميم عبوات مبتكرة وجذابة.",
    image: "https://res.cloudinary.com/duovxefh6/image/upload/v1694940070/pngegg_xva2np.png",
    title: "تصميم إبداعي"
  },
  {
    text: "خدمة السيطرة على الجودة وضمان التماثل في المنتجات. نحن نقدم عمليات فحص دقيقة ومراقبة جودة مشددة لضمان أن منتجاتك تتوافق دائمًا مع المعايير والمواصفات المطلوبة. صورة المراقبة والتماثل.",
    image: "https://images.squarespace-cdn.com/content/v1/5bae488034c4e28133359c8e/1599212976948-XHR5VASV6D1BB1EUXWLB/Control_Consistency_Icon_Graphite_Light.png",
    title: "السيطرة على الجودة"
  },
];
