import React from 'react'

const Costs = () => {
  return (
    <div className='costs'>
      <h3>الاستثمار فى سلسلة محلات (زد) </h3>
      <p>إيمانا منا  بأهمية تشجيع الاستثمار في المشاريع الأعلى ربحًا قررنا منح الاخرين الفرصه للاستثمار بنسبة ربح محدده</p>
    </div>
  )
}

export default Costs
