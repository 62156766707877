export const headerLinks = [
  { path: "/", component: "", icon: "", label_en: "Home", label: "الرئيسية" },
  {
    path: "#about",
    component: "",
    icon: "",
    label_en: "About",
    label: "عن الشركة",
  },
  {
    path: "#services",
    component: "",
    icon: "",
    label_en: "Services",
    label: "الخدمات",
  },
  // { path: "#branches", component: "", icon: "", label_en: "Branches", label: "الفروع" },
  {
    path: "/branches_burchase",
    component: "",
    icon: "",
    label_en: "Branch Purchase",
    label: "تعاقد الفرع",
  },
  {
    path: "#found_us",
    component: "",
    icon: "",
    label_en: "Found Us",
    label: "أين تجدنا",
  },
  {
    path: "#contact",
    component: "",
    icon: "",
    label_en: "Contact",
    label: "تواصل معنا",
  },
  // found_us
];

export const otherHeaderLinks = [
  { path: "/", component: "", icon: "", label_en: "Home", label: "الرئيسية" },
  {
    path: "/branches_burchase",
    component: "",
    icon: "",
    label_en: "Branch Purchase",
    label: "تعاقد الفرع",
  },
  // found_us
];
