import React from 'react'
import './branchiesterms.css'
const BranchiesTerms = () => {
  return (
    <div className='branchiesterms'>
      <h4>تكاليف الفرع</h4>
      <p>نحن أول وأكبر سلسلة سوبر ماركت متخصصة في تقديم جميع المنتجات
      الغذائية الصحية، وأحد أهم المشروعات الرائدة في مصر والوطن العربي.</p>
      <p>
      مع شعار "أكل صحي لكل الناس"، تم تأسيس لتكون المرجع الرئيسي للمنتجات الغذائية الآمنة بأسعار تنافسية تتوافق مع إمكانيات كل من يتطلع إلى حياة صحية بشكل مثالي.
      </p>
      <p>توصيل إليك كل م تريد الحصول عليه من مواد غذائيه وطعام طازج</p>
      <p>نمتلك أكثر من فرع فى اكثر من محافظه</p>
    </div>
  )
}

export default BranchiesTerms
