export const benefities = [
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
  {
      text: `benefit Heading`,
      image: require("../assets/icons/discount.png"),
      title: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta at esse ex dolores"
  },
]
