export const banner = [
  {
    text: "مرحبًا بك في خدماتنا المتميزة في مجال تعبئة وتغليف المنتجات الغذائية ومنتجات اللحوم والدواجن. نحن هنا لنلبي احتياجاتك ونقدم لك أفضل الحلول التعبئة والتغليف. نقدم تصميمات مبتكرة لعبوات منتجاتك تساعدك في التميز في السوق وجذب العملاء. اعتمد على خبرتنا واحترافيتنا في مجال التعبئة والتغليف.",
    image: require("../assets/images/pexels-mark-stebnicki-2252482.jpg"),
    title: "مرحبًا بك في خدماتنا"
  },
  {
    text: "نحن نلتزم بأعلى معايير الجودة والاستدامة في عملياتنا. اطمئن إلى أن منتجاتك ستكون دائمًا على أعلى مستوى من التماثل والجودة. سنقوم بتوزيع منتجاتك بكفاءة عالية عبر قنوات التجزئة الرئيسية، مما يضمن وصول منتجاتك إلى أكبر عدد ممكن من الزبائن.",
    image: require("../assets/images/pexels-caio-64613.jpg"),
    title: "أعلى معايير الجودة والاستدامة"
  },
  {
    text: "نحن نعتبر موقعنا منصة تفاعلية تمكنك من اكتشاف وفهم خدماتنا بسرعة وسهولة، والعثور على ما يناسب احتياجاتك بسهولة ويسر. يمكنك أيضًا الاتصال بنا من خلال الموقع للحصول على استفسارات إضافية أو طلب خدماتنا بسهولة. نحن نهتم بتوفير تجربة مريحة ومفيدة لزوارنا عبر موقعنا الإلكتروني.",
    image: require("../assets/images/pexels-mark-stebnicki-2255999.jpg"),
    title: "موقعنا الإلكتروني"
  }
];
